import { PathEnum } from "src/enums/PathEnum";
import { createLink } from "src/utils/createLink";

export const getVacancyById = (data, id) => {
  try {
    const vacancy = data.allStrapiVacancies.edges.find(
      (item) => item.node.id === id
    );

    return {
      ...vacancy.node,
      vacancy_categories: vacancy.node.vacancy_categories.map(({ name }) => ({
        text: name,
        href: createLink(PathEnum.Vacancies, {
          category: name,
        }),
      })),
    };
  } catch {
    return undefined;
  }
};

interface VacancyMeta {
  interesting: string;
  important: string;
}

export const getMeta = (data): VacancyMeta => {
  const meta = {};

  data.allStrapiVacancyMetas.edges.forEach(({ node }) => {
    meta[node.name] = node.text;
  });

  return meta as VacancyMeta;
};
