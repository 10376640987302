import React from "react";

import { Typography, TypographyProps } from "../Typography";

interface Props extends TypographyProps {
  text: string;
}

export const PlainText: React.FC<Props> = ({ text, ...typographyProps }) => {
  return (
    <>
      {text?.split("\n").map((item, index) => (
        <Typography {...typographyProps} key={index}>
          {item}
        </Typography>
      ))}
    </>
  );
};
