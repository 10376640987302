import React, { createRef, useEffect, useState } from "react";

import cn from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { Banner } from "src/components/Banner";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { Link } from "src/components/Link";
import { LinkList } from "src/components/LinkList";
import { PageWrapper } from "src/components/PageWrapper";
import { PlainText } from "src/components/PlainText";
import { PopUp } from "src/components/PopUp";
import { TextList } from "src/components/TextList";
import { Typography } from "src/components/Typography";
import { PathEnum } from "src/enums/PathEnum";
import { useParams } from "src/hooks/useParams";
import { pink200 } from "src/styles/variables.module.scss";
import { createLink } from "src/utils/createLink";

import { getMeta, getVacancyById } from "./utils";
import * as styles from "./Vacancy.module.scss";

const query = graphql`
  query {
    allStrapiVacancies {
      edges {
        node {
          id
          vacancyId
          name
          experience
          description
          requirements
          requirements_title
          how_we_see_you
          how_we_see_you_title
          extra
          extra_title
          vacancy_categories {
            id
            name
          }
          supervisor {
            title
            text
            image {
              localFile {
                publicURL
              }
            }
            backgroundColor
            textColor
          }
        }
      }
    }
    allStrapiVacancyMetas {
      edges {
        node {
          name
          text
        }
      }
    }
  }
`;

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    dataLayer: any[];
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

const Vacancy: React.FC = () => {
  const { id } = useParams();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const data = useStaticQuery(query);
  const vacancy = getVacancyById(data, id);
  const meta = getMeta(data);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [popUpVisible, setVisible] = useState(false);
  const [isFileAttached, setIsFileAttached] = useState(false);
  const [linkRequired, setLinkRequired] = useState(true);
  const [fileName, setFileName] = useState("Загрузить резюме файлом");

  watch();

  const formRef = createRef<HTMLFormElement>();

  const handleFeedbackFormButtonClick = () => {
    window.scrollTo({ top: formRef.current.offsetTop - 70 });
  };

  const handleNameFocus = () => {
    window.dataLayer.push({ event: "career form click name" });
  };

  const handlePhoneFocus = () => {
    window.dataLayer.push({ event: "career form click phone" });
  };

  const handleEmailFocus = () => {
    window.dataLayer.push({ event: " career form click e-mail" });
  };

  const handleAgreeFocus = () => {
    window.dataLayer.push({ event: "career form click agree" });
  };

  const replaceNumbersAndSymbols = (event) => {
    const regex = /[^А-яЁёA-Za-z]/g;
    event.target.value = event.target.value.replace(regex, "");
    return event.target.value;
  };

  const replaceRussianLetters = (event) => {
    const regex = /[А-яЁё\s]/g;
    event.target.value = event.target.value.replace(regex, "");
    return event.target.value;
  };

  const getElementValue = (id) => {
    const variable = document.getElementById(id);
    switch (id) {
      case "file": {
        return variable?.files;
      }
      case "link": {
        return variable?.value;
      }
    }
  };

  const isEmpty = (variable) => {
    return variable.length === 0;
  };

  const fileValidation = (id) => {
    const variable = getElementValue(id);
    if (isEmpty(variable)) {
      setLinkRequired(true);
      setFileName("Загрузить резюме файлом");
      clearErrors(["fileError"]);
      return;
    }

    if (!isFileTypeErrorValidation(id)) {
      clearErrors(["fileError"]);
    }

    setFileName(variable[0].name);
    clearErrors("link");
    setIsFileAttached(true);
    setLinkRequired(false);
  };

  const fileTypeValidation = (variable) => {
    const allowedExtensions =
      /(image\/jpeg|image\/png|application\/pdf|application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document|application\/msword|text\/xml)/g;
    return variable[0].type.match(allowedExtensions) === null;
  };

  const isFileTypeErrorValidation = (id) => {
    const value = getElementValue(id);
    if (fileTypeValidation(value)) {
      setError("fileError", {
        type: "optional",
        message: "Формат файла не тот",
      });
      return true;
    }
    if (value[0].size > 20000000) {
      setError("fileError", {
        type: "optional",
        message: "Файл превышает 20mb",
      });
      return true;
    }
    return false;
  };

  const linkValidation = (id) => {
    const value = getElementValue(id);
    if (!isEmpty(value)) {
      setLinkRequired(true);
      clearErrors(["file"]);
      setIsFileAttached(false);
    }
  };

  const isResumeError = () => errors.link || errors.file || errors.fileError;

  const hasErrors = (errors) => {
    return (
      Object.keys(errors).length >= 2 ||
      (Object.keys(errors).length === 1 && !errors.fileError)
    );
  };

  const handleFormSubmit = handleSubmit((data) => {
    const formData = new FormData();
    const key = Object.keys(data);
    key.forEach((key) => {
      switch (key) {
        case "file": {
          const fileArray = getElementValue("file");
          if (fileArray.length !== 0) {
            formData.append("file", "fileAttached");
          }
          break;
        }
        default: {
          if (data[key] !== "") {
            formData.append(key, data[key]);
          }
          break;
        }
      }
    });

    formData.append("vacancy", vacancy.name);
    formData.append("files", getElementValue("file")[0]);

    setLoading(true);

    fetch(`${process.env.ENV_URL}`, {
      method: "POST",
      body: formData,
    }) //https://career.akbars.digital/api/email/send
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = data?.message || response.status;
          return Promise.reject(error);
        }
        setSuccess(true);
        setVisible(true);
        window.dataLayer.push({ event: "career form send" });
      })
      .catch((error) => {
        window.dataLayer.push({ event: "send form error" });
        const errorMessage =
          error ||
          error.response?.status ||
          "Какая-то ошибка. Попробуйте еще раз";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => setLoading(false));
  });

  useEffect(() => {
    if (!vacancy) {
      navigate(createLink(PathEnum.NotFound));
    }
  }, [vacancy]);

  if (vacancy) {
    return (
      <PageWrapper topAutoScroll title={vacancy.name}>
        <div>
          <Banner backgroundColor={pink200} className={styles.bannerRoot}>
            <Container>
              <Typography variant="h3" color="white" className={styles.title}>
                {vacancy.name}
              </Typography>
              <div className={styles.bannerFooter}>
                <LinkList
                  items={vacancy.vacancy_categories}
                  linkProps={{ hoverFill: "white" }}
                />
                <Link
                  showArrow
                  arrowPosition="left"
                  rounded={false}
                  className={styles.showMoreLink}
                  disableArrowHide
                  href={createLink(PathEnum.Vacancies)}
                >
                  <a className={styles.backToVacancies}>все вакансии</a>
                </Link>
              </div>
            </Container>
          </Banner>
          <Container rootClassName={styles.topContainer}>
            <div className={cn(styles.grid, styles.topGrid)}>
              <div className={styles.leftColumn}>
                <Container rootClassName={styles.intro}>
                  <div className={styles.background}></div>
                  <Typography color="white">
                    Требуемый опыт работы: {vacancy.experience}
                    <br /> Полная занятость
                  </Typography>
                  <Typography color="white">
                    Казань, ул Чистопольская 11 или полная удаленка — на выбор.
                  </Typography>
                </Container>
                <Button
                    color="purple"
                    shadow
                    onClick={handleFeedbackFormButtonClick}
                >
                  <span className={styles.expere}>откликнуться</span>
                </Button>
              </div>
              <Container rootClassName={styles.rightColumn}>
                <PlainText text={vacancy.description} />
              </Container>
            </div>

            <div className={styles.grid}>
              <Container>
                <Typography variant="h4">
                  {vacancy.requirements_title || "Что нам необходимо"}
                </Typography>
                <TextList text={vacancy.requirements} />
                <Typography variant="h4">
                  {vacancy.how_we_see_you_title || "Каким мы тебя видим"}
                </Typography>
                <TextList text={vacancy.how_we_see_you} />
                {vacancy.extra && (
                    <>
                      <Typography variant="h4">
                        {vacancy.extra_title || "Будет плюсом"}
                      </Typography>
                      <TextList text={vacancy.extra} />
                    </>
                )}
              </Container>
              <Container rootClassName={styles.blueColumn}>
                <Typography variant="h4" color="white">
                  Что интересного
                </Typography>
                <TextList text={meta.interesting} color="white" />
                <Typography variant="h4" color="white">
                  Важно
                </Typography>
                <TextList text={meta.important} color="white" />
              </Container>
            </div>
          </Container>

          <Container
            rootClassName={styles.rootSupervisor}
            className={styles.supervisor}
            style={{
              backgroundColor: vacancy.supervisor.backgroundColor,
            }}
          >
            <img
              src={vacancy.supervisor.image.localFile.publicURL}
              alt="supervisor"
            />
            <div>
              <Typography variant="h4" color={vacancy.supervisor.textColor}>
                {vacancy.supervisor.title}
              </Typography>
              <PlainText
                text={vacancy.supervisor.text}
                color={vacancy.supervisor.textColor}
              />
            </div>
          </Container>
          <form ref={formRef} onSubmit={handleFormSubmit}>
            <Container
              rootClassName={styles.rootFeedback}
              className={cn(
                styles.feedback,
                hasErrors(errors) ? styles.isErr : null
              )}
            >
              <Typography variant="h4" color="white">
                Откликнуться на вакансию
              </Typography>
              <div>
                <input
                  type="text"
                  placeholder="Имя"
                  {...register("firstName", {
                    required: true,
                  })}
                  onFocus={handleNameFocus}
                  className={errors.firstName ? styles.isValid : null}
                  onInput={(e) => replaceNumbersAndSymbols(e)}
                />
                <input
                  type="text"
                  placeholder="Фамилия"
                  {...register("lastName", {
                    required: true,
                  })}
                  className={errors.lastName ? styles.isValid : null}
                  onInput={(e) => {
                    replaceNumbersAndSymbols(e);
                  }}
                />
                <InputMask
                  mask="+7 (999) 999-99-99"
                  type="tel"
                  placeholder="Телефон"
                  {...register("phone", {
                    required: true,
                    minLength: 17,
                    validate: (value) => value[value.length - 1] !== "_",
                  })}
                  onFocus={handlePhoneFocus}
                  className={errors.phone ? styles.isValid : null}
                />
                <input
                  type="email" // email
                  placeholder="E-mail"
                  pattern={'^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'}
                  title="Пожалуйста, введите подходящий email"
                  {...register("email", {
                    required: true,
                  })}
                  className={errors.email ? styles.isValid : null}
                  onInput={(e) => {
                    replaceRussianLetters(e);
                  }}
                  onFocus={handleEmailFocus}
                />
              </div>
              <div
                className={cn(
                  styles.secondModule,
                  isResumeError ? styles.isError : null
                )}
              >
                <div
                  className={
                    errors.link || errors.file ? styles.resumeNotAttach : null
                  }
                >
                  <input
                    type="text"
                    placeholder="Ссылка на резюме"
                    id="link"
                    {...register("link", {
                      required: linkRequired,
                    })}
                    className={
                      errors.link || errors.file ? styles.isValid : null
                    }
                    onInput={() => {
                      linkValidation("link");
                    }}
                  />
                  <div className={styles.typeSelection}>или</div>
                  <label className={styles.fileInput}>
                    <input
                      type="file"
                      id="file"
                      {...register("file", {
                        required: isFileAttached,
                      })}
                      className={cn(styles.isHidden)}
                      accept="image/jpeg,image/png,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onInput={() => {
                        fileValidation("file");
                      }}
                    />
                    <div
                      className={cn(
                        errors.fileError ? styles.fileError : null,
                        styles.notAttach
                      )}
                    >
                      {fileName}
                    </div>
                    <div
                      className={cn(
                        errors.fileError ? styles.fileIsHuge : styles.isHidden,
                        styles.typeSelection
                      )}
                    >
                      {errors?.fileError?.message}
                    </div>
                    <div
                      className={cn(
                        styles.typeSelection,
                        styles.fileRestriction
                      )}
                    >
                      JPG, PNG, DOC, PDF <br />
                      Файл до 20Mb
                    </div>
                  </label>
                </div>
                <label>
                  <input
                    type="checkbox"
                    name="Agree"
                    {...register("checkbox", {
                      required: "checkbox ",
                    })}
                    onFocus={handleAgreeFocus}
                    className={errors.checkbox ? styles.isValid : null}
                  />
                  <span>
                    Я подтверждаю, что ознакомлен и согласен с{" "}
                    <a href="/personal-data.pdf" target="_blank">
                      условиями обработки и использования моих персональных
                      данных
                    </a>
                  </span>
                </label>
              </div>
              <div
                className={
                  hasErrors(errors) ? styles.hasErrors : styles.isHidden
                }
              >
                Все поля должны быть заполнены
              </div>
              <Button
                loading={loading}
                type="submit"
                variant="outlined"
                color={success ? "green" : "purple"}
                shadow
                disabled={loading || success}
              >
                <span>
                  {success ? "отправлено": "отправить"}
                </span>
              </Button>
            </Container>
          </form>
        </div>
        <PopUp visible={popUpVisible} setVisible={setVisible} />
      </PageWrapper>
    );
  }

  return null;
};

export default Vacancy;
