import React from "react";

import { Typography } from "src/components/Typography";

import * as styles from "./TextList.module.scss";

interface Props {
  text: string;
  color?: "white" | "black";
}

export const TextList: React.FC<Props> = ({ text, color = "black" }) => {
  return (
    <ul className={styles.root}>
      {text.split("\n").map((item, index) => (
        <li key={index} className={styles[color]}>
          <Typography color={color}>{item.trim()}</Typography>
        </li>
      ))}
    </ul>
  );
};
