import React from "react";

import cn from "classnames";
import { Link, Props as LinkProps } from "src/components/Link";

import * as styles from "./LinkList.module.scss";

export interface LinkListItem {
  text: string;
  href: string;
  active: boolean;
}

interface Props {
  items: LinkListItem[];
  linkProps?: LinkProps;
  className?: string;
  flexWrap?: boolean;
  startLink?: React.ReactNode;
}

export const LinkList: React.FC<Props> = ({
  items,
  linkProps,
  flexWrap,
  className,
  startLink,
}) => {
  return (
    <div className={cn(styles.root, className, flexWrap && styles.wrap)}>
      {startLink}
      {items.map((item, index) => (
        <Link
          {...linkProps}
          className={cn(linkProps?.className, styles.link)}
          key={index}
          href={item.href}
          active={item.active}
        >
          {item.text}
        </Link>
      ))}
    </div>
  );
};
