import React from "react";

import cn from "classnames";
import { Button } from "src/components/Button";

import * as styles from "./PopUpWindow.module.scss"
import post from './post.svg';



interface Props {
  visible: boolean,
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const PopUp: React.FC<Props> = ({visible, setVisible}) => {

    if (visible) {
      document.body.classList.add(`${styles.overflow}`);

      return (
        <>
          <div className={styles.root} onClick={() => {
            setVisible(false)
          }}>
            <div className={styles.popup} onClick={e => e.stopPropagation()}>
              <img src={post} alt="post" className={styles.img}/>
              <span className={cn(styles.text, styles.font)}>Отклик отправлен. Спасибо!</span>
              <Button
                type="submit"
                variant="outlined"
                color="violet"
                shadow
                onClick={() => {
                  setVisible(false)}}
              >
                <span className={styles.font}>окей</span>

              </Button>
            </div>
          </div>
        </> )
    }

    document.body.classList.remove(`${styles.overflow}`);
    return null;

};