import React from "react";

import { PageProps } from "gatsby";
import { ParamsProvider } from "src/components/ParamsProvider";
import Vacancy from "src/layouts/Vacancy";

const VacancyPage: React.FC = ({ location }: PageProps) => {
  return (
    <ParamsProvider location={location}>
      <Vacancy />
    </ParamsProvider>
  );
};

export default VacancyPage;
