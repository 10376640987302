// extracted by mini-css-extract-plugin
export var bannerRoot = "Vacancy-module--bannerRoot--2weZF";
export var title = "Vacancy-module--title--3-lDV";
export var bannerFooter = "Vacancy-module--bannerFooter--3ks5p";
export var showMoreLink = "Vacancy-module--showMoreLink--2bHRb";
export var backToVacancies = "Vacancy-module--backToVacancies--1W9g1";
export var grid = "Vacancy-module--grid--1Bjxh";
export var topContainer = "Vacancy-module--topContainer--2q4zT";
export var topGrid = "Vacancy-module--topGrid--O6FTQ";
export var leftColumn = "Vacancy-module--leftColumn--XpPAF";
export var background = "Vacancy-module--background--348iM";
export var intro = "Vacancy-module--intro--3TNcx";
export var rightColumn = "Vacancy-module--rightColumn--2vZnx";
export var blueColumn = "Vacancy-module--blueColumn--1gIlF";
export var rootSupervisor = "Vacancy-module--rootSupervisor--24QmH";
export var supervisor = "Vacancy-module--supervisor--1qkwo";
export var rootFeedback = "Vacancy-module--rootFeedback--2K1E_";
export var feedback = "Vacancy-module--feedback--Z5ydW";
export var isErr = "Vacancy-module--isErr--1TC45";
export var isValid = "Vacancy-module--isValid--omWui";
export var resumeNotAttach = "Vacancy-module--resumeNotAttach--1XCwO";
export var secondModule = "Vacancy-module--secondModule--bwEwD";
export var typeSelection = "Vacancy-module--typeSelection--1t07o";
export var fileInput = "Vacancy-module--fileInput--vsIpJ";
export var notAttach = "Vacancy-module--notAttach--2SGQT";
export var fileIsHuge = "Vacancy-module--fileIsHuge--2fbmz";
export var fileRestriction = "Vacancy-module--fileRestriction--1YPOn";
export var hasErrors = "Vacancy-module--hasErrors--2Y_L3";
export var fileError = "Vacancy-module--fileError--33roV";
export var isError = "Vacancy-module--isError--2We-x";
export var isHidden = "Vacancy-module--isHidden--3vmzt";